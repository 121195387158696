import React from 'react';
import { map } from 'lodash';
import PageTitle from 'components/common/PageTitle';
import { Typography } from '@material-ui/core';
import androidSurfTracker808 from 'images/android-808-surf-tracker.png';
import iosSurfTracker808 from 'images/ios-808-surf-tracker.png';
import AppItem from './parts/AppItem';

// If this thing has a backend one day, this will come from a DB.
const APP_LIST = [
  {
    appTitle: '808 Surf Tracker',
    appDescription: `A very simple mobile app, used to track the surf and provide surf related news 
      for the 808 state.`,
    googleImgSrc: androidSurfTracker808,
    googleImgAlt: 'android-808-surf-tracker',
    googleAppLink: 'https://play.google.com/store/apps/details?id=com.bitsandspaces.surftracker' +
      '808&pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1',
    appleImgSrc: iosSurfTracker808,
    appleImgAlt: 'ios-808-surf-tracker',
    appleAppLink: 'https://apps.apple.com/us/app/android-808-surf-tracker/id1550291243?' + 
      'itsct=apps_box&amp;itscg=30200'
  }
];

const AppList = () => {
  return (<>
    <PageTitle title="App List"/>
    {map(APP_LIST, appItem => <AppItem key={appItem.googleAppLink} {...appItem} />)}
    <div className="text-center ml-4 mr-4" style={{ marginTop: '5rem', marginBottom: '10rem'}}>
      <Typography variant="h4">More apps in the making...</Typography>
    </div>
  </>);
};

export default AppList;
