import React from 'react';
import { map } from 'lodash';
import { Typography } from '@material-ui/core';

const AppUpdate = ({ updateTitle, updateDate, updateList}) => {
  return (<>
    <div className="ml-4 mr-4" style={{ marginTop: '5rem', marginBottom: '5rem' }}>
      <div className="mb-4">
        <Typography variant="h6" className="mb-2">{updateTitle}</Typography>
        <Typography variant="body2" className="mb-4">{updateDate.toString()}</Typography>
        <Typography variant="body1">
          <ul>
            {map(updateList, (updateNote, key) => <li key={key}>{updateNote}</li>)}
          </ul>
        </Typography>
      </div>
    </div>
  </>);
};

export default AppUpdate;
