import React, { useState } from 'react';
import { Grid, makeStyles, Typography } from '@material-ui/core';
import mobileDevices from 'images/mobile-devices.png';
import surfTracker808 from 'images/android-808-surf-tracker.png';
import { useTextStyles } from 'utils/StylesUtil';
import MyDialog from 'components/common/MyDialog';

const attributions = [
  (<li key="android">
    android image by
      <a href={"https://pixabay.com/users/openclipart-vectors-30363/?utm_source=link-attribution" +
        "&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1294370"} target="_blank"
        rel="noreferrer">
        &nbsp;OpenClipart-Vectors&nbsp;
      </a> 
      from 
      <a href={"https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;" + 
        "utm_campaign=image&amp;utm_content=1294370"} target="_blank" rel="noreferrer">
        &nbsp;Pixabay&nbsp;
      </a>
  </li>),
  (<li key="iphone">
    iphone image by
      <a href={"https://pixabay.com/users/openclipart-vectors-30363/?utm_source=link-attribution" +
        "&amp;utm_medium=referral&amp;utm_campaign=image&amp;utm_content=160307"} target="_blank"
        rel="noreferrer">
        &nbsp;OpenClipart-Vectors&nbsp;
      </a> 
      from 
      <a href={"https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;" +
        "utm_campaign=image&amp;utm_content=160307"} target="_blank" rel="noreferrer">
        &nbsp;Pixabay&nbsp;
      </a>
  </li>),
  (<li key="tablet">
    tablet image by
      <a href={"https://pixabay.com/users/mmi9-1424200/?utm_source=link-attribution&amp;" +
        "utm_medium=referral&amp;utm_campaign=image&amp;utm_content=1315651"} target="_blank"
        rel="noreferrer">
        &nbsp;mmi9&nbsp;
      </a> 
      from 
      <a href={"https://pixabay.com/?utm_source=link-attribution&amp;utm_medium=referral&amp;" +
        "utm_campaign=image&amp;utm_content=1315651"} target="_blank" rel="noreferrer">
        &nbsp;Pixabay&nbsp;
      </a>
  </li>)
];

const useStyles = makeStyles(theme => ({
  bg: {
    backgroundColor: theme.palette.astronaut.light
  }
}));

const Home = () => {
  const classes = useStyles();
  const textStyles = useTextStyles();
  const [isOpen, setIsOpen] = useState(false);

  return (<>
    <MyDialog title="Attributions" isOpen={isOpen} setIsOpen={setIsOpen}>
      Original unedited images:
      <ul>
        {attributions}
      </ul>
    </MyDialog>
    <div className="ml-4 mt-4">
      <Typography variant="h2">Bits and Spaces 
        <span className={textStyles.mainAccent}>&nbsp;Apps</span>
      </Typography>
    </div>
    <div className={`pl-4 pr-4 pt-5 ${classes.bg}`} style={{ marginTop: '7rem', marginBottom: '7rem' }}>
      <img src={mobileDevices} alt="mobile-devices" className="w-100" />
      <div className={`${textStyles.externalLinksLight} pb-3`} onClick={() => setIsOpen(true)}>
        <Typography variant="subtitle2">Click here for image attributions</Typography>
      </div>
    </div>
    <Grid container style={{ marginTop: '4rem', marginBottom: '10rem' }}>
      <Grid item md={6} xs={12} className="pl-4 pr-4">
        <img src={surfTracker808} alt="android-808-surf-tracker" 
          className="bas-rounded-corners bas-square-shadow w-75" />
      </Grid>
      <Grid item md={6} xs={12} className="pl-4 pr-4">
        <Typography variant="body2" className={`mt-3 ${textStyles.mainAccent}`}>
          808 Surf Tracker
        </Typography>
        <Typography variant="subtitle2" className="mt-2">
          First mobile app released by Bits and Spaces LLC
        </Typography>
        <Typography variant="body2">
          Released the 808 Surf Tracker app on the Google Play Store. First mobile app released 
          under the Bits and Spaces brand.
        </Typography>
      </Grid>
    </Grid>
  </>);
};

export default Home;
