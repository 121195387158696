import React from 'react';
import { map } from 'lodash';
import { Link } from "react-router-dom";
import { makeStyles, Typography, useMediaQuery } from '@material-ui/core';
import { Announcement, Home, List } from '@material-ui/icons'; 
import logo from 'images/bits-and-spaces-apps.png';
import NavButtonLinks from './parts/NavButtonLinks';

const useStyles = makeStyles(theme => ({
  navColor: {
    backgroundColor: theme.palette.haiti.main
  },
  navbarTextLinks: {
    color: '#fefefe',
    fontSize: '1.25rem',
    marginLeft: '5rem',
    '&:hover': {
      textDecoration: 'none',
      color: theme.palette.secondary.light
    }
  }
}));

const navLinks = [
  { name: 'App News', to: '/app-news', icon: <Announcement className="align-text-bottom" /> },
  { name: 'App List', to: '/app-list', icon: <List className="align-text-bottom" /> },
  { name: 'Bits and Spaces', to: '/bits-and-spaces', icon: <Home className="align-text-bottom" /> }
];

const Header = () => {
  const minWidthMediaQuery = useMediaQuery(theme => theme.breakpoints.up('md'));
  const classes = useStyles();

  return (<div id="top" className={classes.navColor}>
    <nav className="d-flex align-items-center justify-content-between">
      <div className="d-flex align-items-center mt-2 mb-2">
        <Link to="/" className="bas-brand-icon">
          <img src={logo} alt="bits-and-spaces-logo" />
        </Link>
        {minWidthMediaQuery && <div>
          {map(navLinks, navLink => <Link key={navLink.to} to={navLink.to} 
            className={classes.navbarTextLinks}>
            <Typography variant="body1" className="d-inline-block">
              {navLink.name}
            </Typography>
          </Link>)}
        </div>}
      </div>
      {!minWidthMediaQuery && <div className="mr-4">
        <NavButtonLinks navLinks={navLinks} />
      </div>}
    </nav>
  </div>);
};

export default Header;
