import React from 'react';
import { map } from 'lodash';
import { Typography } from '@material-ui/core';
import PageTitle from 'components/common/PageTitle';
import AppUpdate from './parts/AppUpdate';

const APP_UPDATES = [
  {
    updateTitle: 'Launch 808 Surf Tracker for iOS',
    updateDate: new Date('Tue Jan 23 2021 00:03:30 GMT-1000'),
    updateList: [
      'Submitted review around 02:00 and approved at around 03:30',
      'Similar to android release, ads are taking a while and sometimes receiving no ad errors.'
    ]
  },
  {
    updateTitle: 'Launch Bits and Spaces websites',
    updateDate: new Date('Tue Jan 15 2021 00:02:00 GMT-1000'),
    updateList: [
      'https://bitsandspaces.com is live.',
      'https://apps.bitsandspaces.com is live.'
    ]
  },
  {
    updateTitle: 'Launch 808 Surf Tracker for Android',
    updateDate: new Date('Tue Jan 1 2021 00:01:00 GMT-1000'),
    updateList: [
      'Published initial release in the Google Play Store.',
      'Original plan with better data view pushed until next update due to time constraint.'
    ]
  }
]

const AppNews = () => {
  return (<>
    <PageTitle title="App News"/>
    {map(APP_UPDATES, (appUpdate, key) => <AppUpdate key={key} {...appUpdate} />)}
    <div className="text-center ml-4 mr-4" style={{ marginTop: '7rem', marginBottom: '15rem' }}>
      <Typography variant="h4">No further update...</Typography>
    </div>
  </>);
};

export default AppNews;
