import React from 'react';
import { Grid, Typography } from '@material-ui/core';
import googlePlayBadge from 'images/google-play-badge.png';
import appleStoreBadge from 'images/apple-app-store-badge-us-uk.svg';

const AppItem = ({ appTitle, appDescription, googleImgSrc, googleImgAlt, googleAppLink,
  appleImgSrc, appleImgAlt, appleAppLink }) => {
  return (<div className={`ml-4 mr-4`} style={{ marginTop: '5rem', marginBottom: '5rem' }}>
    <Grid container>
      <Grid item md={6} xs={12} className="pl-4 pr-4 pb-3">
        <Typography variant="h6" className="mt-2">
          {appTitle}
        </Typography>
        <Typography variant="subtitle1">
          {appDescription}
        </Typography>
        <div>
          {googleAppLink ? 
            <a href={googleAppLink} target="_blank" rel="noreferrer">
              <img alt='Get it on Google Play Store' src={googlePlayBadge} className="w-50"
                style={{ maxWidth: '200px' }} />
            </a>
            :
            <Typography variant="body1">Coming soon to the Google Play Store...</Typography>
          }
          {appleAppLink ? 
            <a href={appleAppLink} target="_blank" rel="noreferrer">
              <img alt='Get it on Apple App Store' src={appleStoreBadge} className="ml-3"
                style={{ width: '40%', maxWidth: '161px' }}/>
            </a>
            :
            <Typography variant="body1">Coming soon to the Apple App Store...</Typography>
          }
        </div>
      </Grid>
      <Grid container md={6} xs={12} className="pl-4 pr-4 pb-3 pt-3">
        {googleImgSrc && <Grid xs={12} md={6} className="mb-5">
          <img src={googleImgSrc} alt={googleImgAlt} style={{ width: '80%', maxWidth: '300px' }}
            className="bas-rounded-corners bas-square-shadow" />
        </Grid>}
        {appleImgSrc && <Grid xs={12} md={6}>
          <img src={appleImgSrc} alt={appleImgAlt} style={{ width: '80%', maxWidth: '300px' }}
            className="bas-rounded-corners bas-square-shadow" />
        </Grid>}
      </Grid>
    </Grid>
  </div>);
};

export default AppItem;
