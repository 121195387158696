import React from 'react';
import { Link } from 'react-router-dom';
import { useMediaQuery, IconButton, makeStyles, Typography, Link as ExtLink } 
  from '@material-ui/core';
import { Facebook, Instagram, Twitter, YouTube } from '@material-ui/icons';
import logo from 'images/bits-and-spaces-apps.png';
import { useTextStyles } from 'utils/StylesUtil';


const useStyles = makeStyles(theme => ({
  bg: {
    backgroundColor: theme.palette.kangaroo.dark
  },
  socialMediaIcons: {
    color: theme.palette.haiti.light
  }
}));
const Footer = () => {
  const classes = useStyles();
  const textStyles = useTextStyles();
  const minWidthMediaQuery = useMediaQuery(theme => theme.breakpoints.up('md'));

  return (<div className={classes.bg}>
    <div className="d-flex flex-column justify-content-start align-items-start pl-4 pt-4">
      <Typography variant="subtitle1">Please follow, like, and subscribe</Typography>
      <div className={`d-flex flex-row`}>
        <IconButton href="https://www.facebook.com/Bits-and-Spaces-106036941466976"
          target="_blank" rel="noreferrer">
          <Facebook className={classes.socialMediaIcons} />
        </IconButton>
        <IconButton href="https://www.instagram.com/bits.and.spaces/"
          target="_blank" rel="noreferrer">
          <Instagram className={classes.socialMediaIcons} />
        </IconButton>
        <IconButton href="https://twitter.com/BitsAndSpaces"
          target="_blank" rel="noreferrer">
          <Twitter className={classes.socialMediaIcons} />
        </IconButton>
        <IconButton href="https://www.youtube.com/channel/UCYPLjxV9dSCNpqTkm4UqcxA"
          target="_blank" rel="noreferrer">
          <YouTube className={classes.socialMediaIcons} />
        </IconButton>
      </div>
    </div>
    <div className={`d-flex flex-${minWidthMediaQuery ? 'row' : 'column'} justify-content-center 
      pt-4 align-items-${minWidthMediaQuery ? 'center' : 'end pr-4'}`}>
      <LegalLink to="/legal/terms-of-use" title="Terms of Use" textStyles={textStyles} />
      <LegalLinkSeparator separate={minWidthMediaQuery} />
      <LegalLink to="/legal/privacy-policy" title="Privacy Policy" textStyles={textStyles} />
      <LegalLinkSeparator separate={minWidthMediaQuery} />
      {/* SPA behavior loads the page, but the scroll bar doesn't go back up, so it looks confusing 
        since the home link is in the footer section. This fixes that. */}
      <Typography variant="body2">
        <a href="https://bitsandspaces.com/legal/aup" className={textStyles.externalLinks}>
          Acceptable Use Policy
        </a>
      </Typography>
      <LegalLinkSeparator separate={minWidthMediaQuery} />
      <LegalLink to="/legal/cookie-policy" title="Cookie Policy" textStyles={textStyles} />
      <LegalLinkSeparator separate={minWidthMediaQuery} />
      <LegalLink to="/legal/disclaimer" title="Disclaimer" textStyles={textStyles} />
    </div>
    <div className={`d-flex flex-${minWidthMediaQuery ? 'row' : 'column'} justify-content-center 
      pt-4 align-items-${minWidthMediaQuery ? 'center' : 'end pr-4'}`}>
      <div className={`d-inline-block ${textStyles.externalLinks}`} 
        onClick={() => window.displayPreferenceModal()}>
        <Typography variant="body2">
          Manage Cookie Preferences / Do Not Sell My Info
        </Typography>
      </div>
    </div>
    <div className="d-flex justify-content-between align-items-center pt-4 pb-2 pb-5">
      {/* SPA behavior loads the page, but the scroll bar doesn't go back up, so it looks confusing 
        since the home link is in the footer section. This fixes that. */}
      <ExtLink href="https://bitsandspaces.com" className="bas-brand-icon ml-4">
        <img src={logo} alt="bits-and-spaces-logo" />
      </ExtLink>
      <Typography variant="body2" className="mr-4">
        © 2021 Bits and Spaces LLC
      </Typography>
    </div>
  </div>);
};

export default Footer;

const LegalLink = ({ to, title, textStyles, href }) => (<Link to={to} href={href}
  className={textStyles.externalLinks}>
  <Typography variant="body2" className="d-inline-block">
    {title}
  </Typography>
</Link>);

const LegalLinkSeparator = ({ separate }) => (<>
  {separate && <Typography variant="body2" className="d-inline-block ml-2 mr-2">
    |
  </Typography>}
</>);
