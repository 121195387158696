import React from 'react';
import { Switch, Route } from "react-router-dom";
import Home from 'components/pages/home/Home';
import AppNews from 'components/pages/app-news/AppNews';
import AppList from 'components/pages/app-list/AppList';

const Body = () => {
  return (<Switch>
    <Route exact path="/" component={Home} />
    <Route exact path="/app-news" component={AppNews} />
    <Route exact path="/app-list" component={AppList} />

    {/* External links */}
    <ExternalLink path="/bits-and-spaces" href="https://bitsandspaces.com" />
    <ExternalLink path="/legal/terms-of-use" 
      href="https://app.termly.io/document/terms-and-conditions/c15c44ce-ee67-4694-811e-5199a1d860ce" />
    <ExternalLink path="/legal/privacy-policy" 
      href="https://app.termly.io/document/privacy-policy/38df1a52-8bdb-467c-86ec-4b7932072229" />
    <ExternalLink path="/legal/cookie-policy" 
      href="https://app.termly.io/document/cookie-policy/0d3ac39f-2f87-4147-96ce-4766ba965add" />
    <ExternalLink path="/legal/disclaimer" 
      href="https://app.termly.io/document/disclaimer/7b232f9b-f213-4371-9359-9760b595ee53" />
  </Switch>);
};

export default Body;

const ExternalLink = ({ path, href }) => (<Route exact path={path} component={() => { 
  window.location.href = href; 
  return null;
}}/>);
